/* eslint-disable import/prefer-default-export */
import { getFunctions, httpsCallable, HttpsCallableResult } from 'firebase/functions';

export function callFunction<Request, Response>(functionName: string, request?: Request) {
    const functions = getFunctions();
    const callFunction = httpsCallable(functions, functionName);

    if (request == null) {
        return callFunction() as Promise<HttpsCallableResult<Response>>;
    }

    return callFunction(request) as Promise<HttpsCallableResult<Response>>;
}
