/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { Patient } from '@eql-ai/typescript-types';
import { functions } from 'config/ApiService';
import { httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { callFunction } from './funtion';
import { Region } from 'services/region';

export interface ExtendedPatient extends Patient {
    postcode?: string;
    [propName: string]: any;
}

interface AddPatientResponse {
    status: number;
    message: string;
    access_id: string;
    patient_id: string;
}

export function registerPatient(
    patient: ExtendedPatient
): Promise<HttpsCallableResult<AddPatientResponse>> {
    return httpsCallable<{ patient: ExtendedPatient }, AddPatientResponse>(
        functions,
        //this service will be replaced with the new one
        'access-invitePatientToTriage'
    )({ patient });
}


export function updatePatientRecursively(
    patientId: string,
    patient: Patient,
    region: Region,
) {
    return callFunction<
        { patient_id: string; data: Patient; region: Region },
        { status: number; message: string }
    >('patient-update', {
        patient_id: patientId,
        data: patient,
        region,
    });
}